import { Box, makeStyles } from '@material-ui/core';
import { ReactComponent as ShopIcon } from 'assets/icons/shop-outline.svg';
import { ReactComponent as BellIcon } from 'assets/icons/bell-alert.svg';
import progressBarColors from 'theme/progressBarColors';
import clsx from 'clsx';

export const IMAGE_WIDTH = 40;
export const GRID_GAP = 16;

const useStyles = makeStyles((theme) => ({
    placeholderImage: {
        width: IMAGE_WIDTH,
        height: IMAGE_WIDTH,
        background: theme.customPalette.border,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
    },
    mainText: {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    lesserText: {
        marginTop: 4,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(16),
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: theme.customPalette.boxShadow,
    },
    texts: {
        display: 'flex',
        flexShrink: 1,
        flexGrow: 1,
        flexDirection: 'column',
        overflow: 'hidden',
    },
    notification: {
        flexShrink: 0,
        display: 'flex',
        alignItems: 'center',
        color: theme.customPalette.boxShadow,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(16),
        fontWeight: 600,
        gap: 8,
    },
    notificationActive: {
        color: progressBarColors.bad,
    },
    bellIcon: {
        fill: theme.customPalette.boxShadow,
    },
    bellIconActive: {
        fill: progressBarColors.bad,
    },
}));

type Props = {
    region: string;
    zipCode: string;
    town: string;
    street: string;
    system?: string;
    notificationCount: number;
};

const Header: React.FC<Props> = ({ town, street, region, zipCode, system, notificationCount }) => {
    const classes = useStyles();

    const mainText = [town, street].join(' • ');
    const lesserText = [region, zipCode, system].filter((i) => i).join(' • ');
    const hasNotifications = notificationCount > 0;

    return (
        <Box display={'flex'} gridGap={GRID_GAP}>
            <Box className={classes.placeholderImage}>
                <ShopIcon width={24} height={24} />
            </Box>

            <Box className={classes.texts}>
                <Box className={classes.mainText}>{mainText}</Box>
                <Box className={classes.lesserText}>{lesserText}</Box>
            </Box>

            <Box className={clsx(classes.notification, hasNotifications && classes.notificationActive)}>
                <BellIcon className={hasNotifications ? classes.bellIconActive : classes.bellIcon} />

                {notificationCount}
            </Box>
        </Box>
    );
};

export default Header;
