import { Box, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import TextFieldRHF from 'components/textField/textFieldRHF';
import { useTranslation } from 'react-i18next';
import { Control, FieldValues, useWatch } from 'react-hook-form';
import { DashboardDataSource } from 'screens/dashboard/types';

type Props = {
    control: Control<FieldValues>;
};

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 700,
        lineHeight: theme.typography.pxToRem(16),
    },
    description: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 400,
        lineHeight: theme.typography.pxToRem(20),
        flexShrink: 1,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    selectContainer: {
        flexShrink: 0,
        marginRight: theme.spacing(3),
        width: 392,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            width: '100%',
            marginBottom: theme.spacing(1.5),
        },
    },
}));

const DataSource: React.FC<Props> = ({ control }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const defaultValue: DashboardDataSource = 'duration';
    const selectedValue = useWatch({ name: 'dataSource', control, defaultValue });

    const durationLabel = t('dashboard.dataSource.duration.label');
    const durationDescription = t('dashboard.dataSource.duration.description');
    const occurrenceLabel = t('dashboard.dataSource.occurrence.label');
    const occurrenceDescription = t('dashboard.dataSource.occurrence.description');

    return (
        <>
            <Box mb={0.5} className={classes.label}>{`${t('dashboard.dataSource.label')}:`}</Box>
            <Box className={classes.row}>
                <Box className={classes.selectContainer}>
                    <TextFieldRHF control={control} defaultValue={defaultValue} name="dataSource" select size="medium">
                        <MenuItem value={'duration'}>{durationLabel}</MenuItem>
                        <MenuItem value={'occurrence'}>{occurrenceLabel}</MenuItem>
                    </TextFieldRHF>
                </Box>

                <Box className={classes.description}>
                    {selectedValue === 'duration' && durationDescription}
                    {selectedValue === 'occurrence' && occurrenceDescription}
                </Box>
            </Box>
        </>
    );
};

export default DataSource;
