import { TFunction } from 'react-i18next';

export const formatNumber = (input: number, unit: 'hour' | 'percent', t: TFunction, withoutSign = false) => {
    const fixedSize = unit === 'percent' ? 2 : 2;
    const value = parseFloat(input.toFixed(fixedSize));

    const getSign = (input: number) => {
        if (input === 0) {
            return '';
        }

        return input < 0 ? '-' : '+';
    };

    const sign = withoutSign ? '' : getSign(input);

    return unit === 'hour' ? `${value}h` : `${sign}${withoutSign ? value : Math.abs(value)}%`;
};
