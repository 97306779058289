import { makeStyles } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { TooltipProps } from 'recharts';
import { formatNumber } from 'screens/dashboard/helpers';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        background: theme.customPalette.background.semiTransparentBlack,
        borderRadius: 4,
        color: 'white',
        textAlign: 'right',
        fontSize: 11,
        lineHeight: '16px',
    },
    emphasize: {
        fontWeight: 700,
    },
}));

type Props = TooltipProps<string, string>;

const ChartTooltip: React.FC<Props> = ({ payload }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (!payload || payload.length === 0) {
        return null;
    }

    const indexZero = 0;
    const firstPayload = payload[indexZero];
    const data = firstPayload['payload'];

    const { store, overall, unit, storeToday, overallToday, miliseconds } = data;

    const storeValue = store || storeToday;
    const overallValue = overall || overallToday;
    if (storeValue === undefined || overallValue === undefined) {
        return null;
    }

    const dateTime = DateTime.fromMillis(miliseconds).toFormat('d. M. y');

    return (
        <div className={classes.container}>
            {dateTime} {t('dashboard.chartTooltip.overall')}: <span className={classes.emphasize}>{formatNumber(overallValue, unit, t, true)}</span>
            <br />
            {t('dashboard.chartTooltip.store')}: <span className={classes.emphasize}>{formatNumber(storeValue, unit, t, true)}</span>
        </div>
    );
};

export default ChartTooltip;
