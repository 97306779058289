import Main from 'components/main/main';
import Header from 'components/header/header';
import SearchBox from 'components/searchBox/searchBox';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { useHandledForm } from 'shared/useHandledForm/useHandledForm';
import HorizontalSeparator from 'components/horizontalSeparator/horizontalSeparator';
import DataSource from './components/dataSource/dataSource';
import Filters from './components/filters/filters';
import OrderBy from './components/orderBy/orderBy';
import CardList from './components/cardList/cardList';
import { DashboardPeriod, DashboardDataSource } from './types';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: 1214,
        padding: `0 ${theme.spacing(2)}`,
        alignSelf: 'center',
    },
    title: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: 700,
        lineHeight: theme.typography.pxToRem(32),
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            marginTop: theme.spacing(3),
        },
    },
    verticalSpacer: {
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    filtersAndSortRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: theme.spacing(1.5),
        },
    },
}));

type FieldValues = {
    dataSource?: DashboardDataSource;
    period?: DashboardPeriod;
};

const DashboardScreen: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchPhrase, setSearchPhrase] = React.useState<string>('');
    const [order, setOrder] = React.useState<'asc' | 'desc'>('desc');
    const { control, watch } = useHandledForm<FieldValues>();
    const [alarmType, setAlarmType] = React.useState<string | undefined>();
    const [priority, setPriority] = React.useState<1 | 2>(1);
    const dataSource = watch('dataSource');
    const period = watch('period');

    const orderByLabel = dataSource === 'duration' ? t('dashboard.dataSource.duration.label') : t('dashboard.dataSource.occurrence.label');

    const toggleOrder = () => {
        setOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    };

    return (
        <>
            <Header />
            <SearchBox setFilter={setSearchPhrase} />
            <Main>
                <Box flexDirection={'column'} display={'flex'} flexGrow={1}>
                    <Box className={classes.container}>
                        <div className={classes.title}>{t('dashboard.title')}</div>
                        <Box className={classes.verticalSpacer} />

                        <DataSource control={control} />

                        <Box className={classes.verticalSpacer} />
                        <HorizontalSeparator />
                        <Box className={classes.verticalSpacer} />

                        <Box className={classes.filtersAndSortRow}>
                            <Filters control={control} selectedAlarmType={alarmType} selectedPriority={priority} onAlarmTypeChange={setAlarmType} onPriorityChange={setPriority} />
                            <OrderBy order={order} label={orderByLabel} onClick={toggleOrder} />
                        </Box>

                        <Box className={classes.verticalSpacer} />

                        {!!dataSource && !!alarmType && !!period && (
                            <CardList alarmType={alarmType} period={period} dataSource={dataSource} priority={priority} searchPhrase={searchPhrase} sortDirection={order} />
                        )}
                    </Box>
                </Box>
            </Main>
        </>
    );
};

export default DashboardScreen;
